.App {
  margin: 2rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

p {
  max-height: 20rem;
  max-width: 50vw;
  white-space: pre;
  overflow: auto;
  background-color: #FAF9F6;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
